<template>
  <div class="tablePage">
    <el-table :data="tableData"
              @sort-change="sortChange"
              style="width: 100%"
              :max-height="height"
              :header-cell-style="isHeadColor ? {'background-color': '#F9F9F9'} : {}"
              :highlight-current-row="highlightCurrentRow">
      <slot></slot>
      <!-- 暂无数据插槽 -->
      <template slot="empty">
        <div class="noData">
          <img src="../../static/img/nodata.png"
               alt="暂无数据">
        </div>
      </template>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :hide-on-single-page="true"
                   :page-size="50"
                   layout="total, prev, pager, next"
                   :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 500
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    isHeadColor: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleSizeChange (val) {
      this.$emit('size-change', val)
    },
    handleCurrentChange (val) {
      this.$emit('current-change', val)
    },
    sortChange (params) {
      this.$emit('sort-change', params)
    }
  }
}
</script>

<style lang="less" scoped>
.tablePage /deep/ .cell {
  color: #333;
  font-weight: normal;
}
.tablePage /deep/ .el-table td,
.el-table th {
  padding: 23px 0;
}
.tablePage /deep/ .el-table__empty-text {
  width: 100%;
}
.noData {
  width: 100%;
  height: 300px;
  position: relative;
  img {
    display: inline-block;
    width: 150px;
    position: absolute;
    top: 50%;
    margin-top: -76px;
    left: 50%;
    margin-left: -75px;
  }
}
</style>